import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactGA from 'react-ga';
import dayjs from 'dayjs';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import { ThemeProvider } from '../providers/ThemeProvider';
import { getGoogleAnalysticsID } from '../utils/constants';

ReactGA.initialize(getGoogleAnalysticsID(), {
    gaOptions: {
        siteSpeedSampleRate: 100,
    },
    debug: false,
});

class BlogIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSearch:
                typeof window !== 'undefined' ? window.location.search : '',
        };
    }

    render() {
        const { currentSearch } = this.state;
        const { data, location } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;

        return (
            <ThemeProvider>
                <Layout keyId="root" location={location} title={siteTitle}>
                    <SEO
                        title="Trang Chủ"
                        keywords={['blog', 'daonamtien', 'dao nam tien']}
                    />
                    <Bio />
                    {posts.map(({ node }) => {
                        const title =
                            node.frontmatter.title || node.fields.slug;
                        // const date = dayjs(node.frontmatter.date);
                        // const day = date.format('DD');
                        // const month = date.format('MMMM');

                        return (
                            <div key={node.fields.slug}>
                                {/* <div
                                    style={{
                                        position: 'absolute',
                                        left: -70,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div>{month}</div>
                                    <div>{day}</div>
                                </div> */}
                                <div>
                                    <h3
                                        style={{
                                            marginBottom: rhythm(1 / 4),
                                        }}
                                    >
                                        <Link
                                            style={{ boxShadow: 'none' }}
                                            to={`${node.fields.slug}${currentSearch}`}
                                        >
                                            {title}
                                        </Link>
                                    </h3>
                                    <small>{node.frontmatter.date}</small>
                                </div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            node.frontmatter.description ||
                                            node.excerpt,
                                    }}
                                />
                            </div>
                        );
                    })}
                </Layout>
            </ThemeProvider>
        );
    }
}

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        # siteSearchIndex {
        #     index
        # }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        # date
                        title
                        description
                    }
                }
            }
        }
    }
`;
